import React, { Fragment } from 'react';
import styled from 'styled-components';
import { media, colors, fonts } from '../../../globalStyles';
import ContentSection from '../../ContentSection';
import ContentSectionTopBorder from '../../_common/ContentSectionTopBorder';
import P from '../../_common/P';
import H4 from '../../_common/H4';

import iphoneImg from '../../../assets/img/iphone-ios.png';
import linesRound from '../../../assets/img/lines-round.png';
import conceptIcon from '../../../assets/img/concept.png';
import protoIcon from '../../../assets/img/phototyping.png';
import uiIcon from '../../../assets/img/ui-design-icon.png';
import codingIcon from '../../../assets/img/coding.png';
import supportIcon from '../../../assets/img/support.png';
import conceptLine from '../../../assets/img/concept.svg';
import protoLine from '../../../assets/img/prototyping.svg';
import uiLine from '../../../assets/img/ui-design.svg';
import codingLine from '../../../assets/img/coding.svg';
import supportLine from '../../../assets/img/support.svg';

const StyledDevelopmentSteps = styled.div`
	max-width: 1380px;
	margin: 0 auto;
	min-height: 886px;
	position: relative;
	figure {
		max-width: 436px;
		position: absolute;
		left: 50%;
		bottom: 90px;
		transform: translateX(-50%);
		z-index: 100;
		${media.maxLg`max-width: 350px;`}
		${media.maxMd`display: none;`}
		img {
			width: 100%;
		}
	}
	.step {
		position: absolute;
		display: flex;
		align-items: center;
		flex-direction: column;
		max-width: 240px;
		${media.maxMd`
			position: static;
		    transform: translateX(0%) !important;
		    width: 100%;
		    text-align: center;
		    max-width: 100%;
		    margin-bottom: 36px;
		`}
		&:nth-of-type(1) {
			left: 50%;
			top: 6%;
			transform: translateX(-50%);
			${media.maxLg`top: 8%;`}
			${media.tablet`top: 13%;`}
			.step-line {
				top: 23%;
				left: -19px;
			}
		}
		&:nth-of-type(2) {
			left: 10%;
			top: 21%;
			${media.maxLg`
    		    left: 1%;
                top: 23%;
    		`}
			.step-line {
				left: 71%;
				top: 24%;
				${media.tablet`
					left: 61%;
                    top: 32%;
				`}
			}
		}
		&:nth-of-type(3) {
			left: 2%;
			bottom: 11%;
			${media.maxLg`
    		    left: 0;
                bottom: 5%;
    		`}
			.step-line {
				left: 70%;
				top: 20px;
			}
		}
		&:nth-of-type(4) {
			right: 13%;
			top: 19%;
			${media.maxLg`
    		    right: 0;
                top: 25%;
    		`}
			.step-line {
				right: 67%;
				top: 18%;
			}
		}
		&:nth-of-type(5) {
			right: -1%;
			bottom: 11%;
			${media.maxLg`
    		    right: 0;
                bottom: 2%;
    		`}
			.step-line {
				right: 66%;
				top: 7%;
			}
		}
		&-line {
			position: absolute;
			${media.maxMd`display: none;`}
		}
		&-img {
			width: 109px;
			height: 109px;
			border-radius: 50%;
			background-image: url(${linesRound});
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 9px;
			position: relative;
		}
		&-number {
			display: none;
			color: white;
			font-family: ${fonts.robotoRegular};
			font-size: 13px;
			line-height: 14px;
			width: 15px;
			height: 15px;
			background-color: ${colors.orange};
			border-radius: 50%;
			text-transform: uppercase;
			position: absolute;
			right: 3px;
			top: 17px;
			${media.maxMd`
		        display: flex;
			    align-items: center;
			    justify-content: center;
		    `}
		}
		${H4} {
			margin-top: 10px;
			margin-bottom: 10px;
			color: ${colors.blackTitle};
			font-family: ${fonts.robotoRegular};
			font-size: 18px;
			line-height: 26px;
			text-transform: uppercase;
			${media.maxMd`
		        margin-top: 27px;
   			    margin-bottom: 20px;
		    `}
		}
		${P} {
			text-align: center;
			font-size: 13px;
			line-height: 20px;
			${media.maxSm`
		        padding-left: 15px;
                padding-right: 15px;
		    `}
		}
	}
`;

const DevelopmentSteps = () => (
	<Fragment>
		<ContentSection
			title="Custom iOS Apps – From Idea to Fulfillment"
			subtitle="We're with you during every step of the app development lifecycle."
			noTopBorder
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<StyledDevelopmentSteps>
				<figure>
					{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
					<img src={iphoneImg} alt="3d mobile phone image" />
				</figure>
				<div className="step">
					<div className="step-line">
						<img src={uiLine} alt="ui-line" />
					</div>
					<div className="step-img">
						<span className="step-number">1</span>
						<img src={uiIcon} alt="pencil and ruler icon" />
					</div>
					<H4>UI/UX Design</H4>
					<P>
						What’s the personality, demographic, and behaviors of your
						end-users? We help you design an app that caters to their needs.
					</P>
				</div>
				<div className="step">
					<div className="step-line">
						<img src={protoLine} alt="ui-line" />
					</div>
					<div className="step-img">
						<span className="step-number">2</span>
						<img src={protoIcon} alt="square with arrows inside" />
					</div>
					<H4>Prototyping</H4>
					<P>
						Don’t build it without faking it first. It’s important to build an
						app mockup to see what works, what doesn’t, and what you want.
					</P>
				</div>
				<div className="step">
					<div className="step-line">
						<img src={conceptLine} alt="ui-line" />
					</div>
					<div className="step-img">
						<span className="step-number">3</span>
						<img src={conceptIcon} alt="pencil ruler and paper" />
					</div>
					<H4>Concept</H4>
					<P>
						You know where you want to be. Let us help you get there. Whether
						you have an idea or not, <b>PixelTeh</b> is a great place to start.
					</P>
				</div>
				<div className="step">
					<div className="step-line">
						<img src={codingLine} alt="ui-line" />
					</div>
					<div className="step-img">
						<span className="step-number">4</span>
						<img src={codingIcon} alt="greater than and less than" />
					</div>
					<H4>Coding</H4>
					<P>
						Now, it’s time for the technical stuff. Our programmers get to work
						creating an app the works flawlessly according to your
						specifications.
					</P>
				</div>
				<div className="step">
					<div className="step-line">
						<img src={supportLine} alt="ui-line" />
					</div>
					<div className="step-img">
						<span className="step-number">5</span>
						<img src={supportIcon} alt="gray gear" />
					</div>
					<H4>Support</H4>
					<P>
						It’s not over even when it’s over! We provide ongoing support and
						maintenance for our clients – from frontend to backend.
					</P>
				</div>
			</StyledDevelopmentSteps>
		</ContentSection>
		<ContentSectionTopBorder />
	</Fragment>
);
export default DevelopmentSteps;
