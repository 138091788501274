import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import P from '../../_common/P';
import H4 from '../../_common/H4';
import appleMobileApp from '../../../assets/img/apple-mobile-alt.png';
import { media, colors, fonts } from '../../../globalStyles';
import itemIcon from '../../../assets/img/more-icon-alt.png';

const StyledIndustriesApplying = styled.div`
		& > ${P} {
			color: ${colors.blackTitle};
			font-family: ${fonts.openSans};
			font-size: 15px;
			line-height: 30px;
			text-align: left;
			${media.maxSm`
		      padding-left: 15px;
			    padding-right: 15px;
			    text-align: justify;
		    `}
		}
		.ios-apply {
			
			display: flex;
			justify-content: space-between;
			max-width: 1350px;
			margin: 0 auto;
			padding-top: 50px;
			${media.maxSm`flex-direction: column;`}
			.ios-apply-img {
				padding-right: 15px;
				padding-left: 15px;
				${media.minLg`width: 33.33333333%;`}
				${media.minSm`width: 25%;`}
				figure {
					margin-top: 26px;
					${media.maxSm`
							max-width: 333px;
							margin: 0 auto;
				    `}
					${media.maxXs`max-width: 266px;`}
		    		img {
						width: 100%;
					}
				}
			}
		}
		.ios-apply-description {
			padding-right: 15px;
			padding-left: 26px;
			${media.minLg`width: 66.66666667%;`}
			${media.minSm`width: 75%;`}
			${media.maxSm`
				padding-right: 0;
				padding-left: 0;
				margin-left: -15px;
					margin-right: -15px;
			`}
			${H4} {
				color: ${colors.blackTitle};
				font-family: ${fonts.openSans};
				font-size: 22px;
				font-weight: 600;
				line-height: 32px;
				margin: 10px 0 15px 0;
				${media.maxSm`
			        padding: 62px 20px 0px 20px;
                    text-align: center;
			    `}
			}
			.ios-apply-list {
				list-style: none;
				padding-left: 0;
				width: 100%;
				${media.maxSm`
                    padding: 40px 10px;
                    background-color: ${colors.whiteSmoke};
			    `}
				.ios-apply-item {
					color: ${colors.blackTitle};
					font-family: ${fonts.openSans};
					font-size: 15px;
					line-height: 40px;
					position: relative;
					${media.maxSm`
	                    display: inline-block;
				    `}
					&::before {
						content: '';
						display: inline-block;
						width: 16px;
						height: 16px;
						background: url(${itemIcon}) no-repeat center center;
						margin: 10px 13px -2px 3px;
						flex-shrink: 0;
						${media.maxXs`
		                     margin-right: 20px;
					    `}
					}
					strong {
						margin-right: 5px;
						font-family: ${fonts.openSansBold};
					}
				}
			}
		}
`;

const IndustriesApplying = () => (
	<ContentSection
		title="Industries That Benefit from iOS Apps"
		noTopImage={true}
		seoTitle={'h2'}
	>
		<StyledIndustriesApplying>
			<P>
				Has your business been disrupted by mobile apps? You’re not alone. The
				worldwide popularity of mobile phone usage flooded the market space less
				than a decade ago. Since then, it’s taken the world by storm. Now,
				nearly 1 billion people use iPhones to make purchases, check emails,
				send text messages, make calls, and play games. But they are being used
				for much more than that! Over the past 10 years, <b>PixelTeh</b> has
				provided custom iOS development services for a range of industries.
				According to Statista, between 2008 and 2017, the number of apps has
				grown from 800 to more than 2 million apps.
			</P>
			<div className="ios-apply">
				<div className="ios-apply-img">
					<figure>
						<img src={appleMobileApp} alt="apple icon in center of grid" />
					</figure>
				</div>
				<div className="ios-apply-description">
					<H4>
						Our iOS application development company designs, codes, and deploys
						apps that are used for marketing, entertainment, information
						purposes, and even medical.
					</H4>
					<ul className="ios-apply-list">
						<li className="ios-apply-item">
							<strong>Real Estate:</strong>Easily accessible property listings
							and effective lead generation.
						</li>
						<li className="ios-apply-item">
							<strong>Healthcare:</strong>Provides superior access to medical
							information and elevates the total quality of service.
						</li>
						<li className="ios-apply-item">
							<strong>Banking & Finance:</strong>Seamless account tracking,
							budgeting, and customization with top-notch security.
						</li>
						<li className="ios-apply-item">
							<strong>Hospitality & Food Service:</strong>A convenient way to
							make a reservation or order food on the go
						</li>
						<li className="ios-apply-item">
							<strong>Entertainment & Media:</strong>Download the latest music,
							videos, and news for immediate access.
						</li>
						<li className="ios-apply-item">
							<strong>Travel & Lodging:</strong>A custom app makes it easy to
							book a hotel, plan a vacation, or get a ride
						</li>
						<li className="ios-apply-item">
							<strong>Social Networking:</strong>The world moves by social
							media. The best way to connect is with a mobile iOS app.
						</li>
						<li className="ios-apply-item">
							<strong>Education:</strong>A perfect channel to engage, educate,
							and enlighten a wide range of users.
						</li>
						<li className="ios-apply-item">
							<strong>Retail & E-Commerce:</strong>Spreads product awareness and
							increases sales exponentially.
						</li>
					</ul>
				</div>
			</div>
		</StyledIndustriesApplying>
	</ContentSection>
);

export default IndustriesApplying;
