import React, { Fragment } from 'react';
import { Accordion, AccordionItem } from 'react-sanfona';
import styled from 'styled-components';

import arrow from '../../../assets/img/right-arrow.svg';
import accordionLine from '../../../assets/img/accordion-line.png';

import P from '../../_common/P';
import H3 from '../../_common/H3';
import { colors, fonts } from '../../../globalStyles';

const StyledIosAccordeon = styled.div`
	background-color: ${colors.blueDark};
	margin-bottom: -30px;
	.react-sanfona-item {
		.accordion-title {
			display: flex;
			justify-content: center;
			color: ${colors.white};
			font-size: 17px;
			font-weight: 700;
			line-height: 24px;
			text-transform: uppercase;
			padding-top: 27px;
			margin-top: 0;
			padding-bottom: 40px;
			background: url(${accordionLine}) 0 100% repeat-x;
			img {
				width: 40px;
				height: 25px;
				margin-left: 5px;
			}
		}
		&.react-sanfona-item-expanded {
			.accordion-title {
				background: none;
				color: ${colors.redBright};
			}
		}
		.react-sanfona-item-body {
			background: url(${accordionLine}) 0 100% repeat-x;
			${P} {
				padding: 0 5px 55px;
				color: ${colors.white};
				font-family: ${fonts.robotoLightItalic};
				font-size: 15px;
				font-weight: 300;
				line-height: 28px;
			}
		}
		&.react-sanfona-item-expanded {
			img {
				opacity: 0;
			}
		}
	}
`;

const IosAccordeon = () => (
	<StyledIosAccordeon className="visible-sm">
		<Fragment>
			<Accordion>
				<AccordionItem
					title={
						<H3 className="accordion-title">
							Build a Stronger Brand
							<img src={arrow} alt="" />
						</H3>
					}
				>
					<P>
						In today’s mobile-driven world, one of the best ways to develop
						brand recognition is with an iPhone app. Let us develop an iPhone
						app to educate, engage, and excite your customers.
					</P>
				</AccordionItem>
				<AccordionItem
					title={
						<H3 className="accordion-title">
							Keep the Connections Open
							<img src={arrow} alt="" />
						</H3>
					}
				>
					<P>
						More than 4.5 billion people use mobile devices on a daily basis.
						Stay connected by taking advantage of this true game-changer that
						allows for real-time, 24-hour customer interactions.
					</P>
				</AccordionItem>
				<AccordionItem
					title={
						<H3 className="accordion-title">
							Greater User Engagement
							<img src={arrow} alt="" />
						</H3>
					}
				>
					<P>
						A well-built and targeted mobile app is extremely useful in
						increasing the level of engagement with your business. And more
						engagement means more interest and more purchases.
					</P>
				</AccordionItem>
				<AccordionItem
					title={
						<H3 className="accordion-title">
							Faster Mobile Transactions
							<img src={arrow} alt="" />
						</H3>
					}
				>
					<P>
						Online shopping is where it’s at. Put your products and services
						right in front of them and ready to buy. More than 80% of people
						shop via their mobile phones.
					</P>
				</AccordionItem>
			</Accordion>
		</Fragment>
	</StyledIosAccordeon>
);

export default IosAccordeon;
