import React from 'react';
import styled from 'styled-components';
import Services from './Services';
import appleRedImg from './../../../assets/img/apple-big-logo.png';

import { media, colors } from '../../../globalStyles';

const StyledTypography = styled.div`
	.img-cover {
		max-width: 225px;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 80px;
		text-align: center;
		${media.maxSm`
		    max-width: 100px;
		    margin-bottom: 40px;
		    margin-top: 0;
		`}
		img {
			${media.maxSm`
		    width: 100%;
		`}
		}
	}
	hr {
		background-color: ${colors.blackTitle};
		width: 286px;
		height: 1px;
		margin: 0 auto;
		${media.maxXs`
		   width: 215px;
		`}
	}
`;

const Typography = () => (
	<StyledTypography className="android-apps">
		<div className="container-fluid">
			<figure className="img-cover">
				<img src={appleRedImg} alt="big orange apple logo" />
			</figure>
			<hr />
			<Services />
		</div>
	</StyledTypography>
);

export default Typography;
