import React from 'react';
import { shape, string } from 'prop-types';
import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import PageDescription from '../components/TechnologiesIosPage/PageDescription';
import Typography from '../components/TechnologiesIosPage/Typography';
import Features from '../components/TechnologiesIosPage/Features';
import IndustriesApplying from '../components/TechnologiesIosPage/IndustriesApplying';
import DevelopmentSteps from '../components/TechnologiesIosPage/DevelopmentSteps';
import SubmitQuote from '../components/SubmitQuote';
import Applying from '../components/TechnologiesIosPage/Applying';
import Stack from '../components/Stack';

import swift from '../assets/img/swift-icon.png';
import cocoa from '../assets/img/cocoa-pods.png';
import fabric from '../assets/img/fabric.png';
import vuforia from '../assets/img/vuforia.png';
import objective from '../assets/img/objective.png';
import realm from '../assets/img/realm.png';
import unity from '../assets/img/unity3d.png';
import open from '../assets/img/open-logo.png';
import { useHeroPictureTechnologies } from '../hooks/use-hero-picture-technologies';
import { ios } from '../seoConfig';

const stackImages = [
	{
		icon: swift,
		alt: 'swift logo',
	},
	{
		icon: cocoa,
		alt: 'cocoa pods logo',
	},
	{
		icon: fabric,
		alt: 'fabric logo',
	},
	{
		icon: vuforia,
		alt: 'vuforia logo',
	},
	{
		icon: objective,
		alt: 'objective c logo',
	},
	{
		icon: realm,
		alt: 'realm logo',
	},
	{
		icon: unity,
		alt: 'unity3d logo',
	},
	{
		icon: open,
		alt: 'opengl logo',
	},
];
const TechnologiesIosPage = ({ location }) => {
	const img = useHeroPictureTechnologies();

	return (
		<PageLayout location={location} seoConfig={ios}>
			<SubpageTopCover
				backgroundImg={img}
				altText={'pixelteh team with computers'}
			>
				IOS App Development
			</SubpageTopCover>
			<PageDescription />
			<Typography />
			<Features />
			<IndustriesApplying />
			<DevelopmentSteps />
			<SubmitQuote />
			<Applying />
			<Stack title="iOS Apps Technology Stack" images={stackImages} />
		</PageLayout>
	);
};

TechnologiesIosPage.propTypes = {
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
};

export default TechnologiesIosPage;
