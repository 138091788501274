import React from 'react';
import styled from 'styled-components';
import { media, colors, fonts } from '../../../globalStyles';
import H3 from '../../_common/H3';
import iosImg from './../../../assets/img/ios-icon.png';
import macOsImg from './../../../assets/img/macOs.png';
import watchosImg from './../../../assets/img/watchos.png';
import appleImg from './../../../assets/img/apple-icon-black.png';
import iosImgMobile from './../../../assets/img/ios-icon-mobile.png';
import macOsImgMobile from './../../../assets/img/macOs-mobile.png';
import watchosImgMobile from './../../../assets/img/watchos-mobile.png';
import appleImgMobile from './../../../assets/img/apple-icon-black-mobile.png';

const StyledServices = styled.ul`
	display: flex;
	padding: 91px 14px 164px 14px;
	justify-content: center;
	${media.maxSm`
		flex-wrap: wrap;
	    max-width: 480px;
	    margin: 0 auto;
	    padding-top: 64px;
	    padding-bottom: 0;
	`};
	.android-service {
		width: 167px;
		height: 167px;
		background-color: white;
		border-radius: 36px;
		box-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.3);
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 0 18px;
		${media.tablet`
			width: 130px;
            height: 130px;
            margin-bottom: 10px;
		`};
		${media.maxSm`
			width: 167px;
		    height: 167px;
		    margin-bottom: 36px;
		`};
		${media.maxXs`
			width: 107px;
		    height: 107px;
		    border-radius: 23px;
		    margin-bottom: 24px;
		`};
		figure {
			max-width: 225px;
			min-height: 82px;
			margin: 0;
			${media.tablet`
				min-height: 56px;
			`};
			${media.maxXs`
				max-width: 100px;
				min-height: initial;
			`};
		}
		img {
			width: 100%;
		}
		${H3} {
			color: ${colors.darkGrey};
			font-family: ${fonts.robotoRegular};
			font-size: 20px;
			font-weight: 400;
			${media.tablet`
				margin-top: 10px;
			`};
			${media.maxSm`
				font-size: 13px;
			    margin-top: 10px;
			    margin-bottom: 0;
			`};
		}
	}
`;

const Services = () => (
	<StyledServices className="android-services">
		<li className="android-service">
			<figure>
				<img className="hidden-xs" src={iosImg} alt="two mobile phones" />
				<img
					className="visible-xs"
					src={iosImgMobile}
					alt="two small mobile phones"
				/>
			</figure>
			<H3>IOS</H3>
		</li>
		<li className="android-service">
			<figure>
				<img className="hidden-xs" src={macOsImg} alt="two computer screens" />
				<img
					className="visible-xs"
					src={macOsImgMobile}
					alt="two small computer screens"
				/>
			</figure>
			<H3>macOs</H3>
		</li>
		<li className="android-service">
			<figure>
				<img className="hidden-xs" src={watchosImg} alt="apple watch" />
				<img
					className="visible-xs"
					src={watchosImgMobile}
					alt="small apple watch"
				/>
			</figure>
			<H3>watchOs</H3>
		</li>
		<li className="android-service">
			<figure>
				<img className="hidden-xs" src={appleImg} alt="black apple icon" />
				<img
					className="visible-xs"
					src={appleImgMobile}
					alt="small black apple icon"
				/>
			</figure>
			<H3>tvOs</H3>
		</li>
	</StyledServices>
);

export default Services;
