import React from 'react';
import P from '../_common/P';
import PageDescription from '../PageDescription';
import styled from 'styled-components';
import { media } from '../../globalStyles';

const StyledDescriptionBlock = styled.div`
	.content-section-top-border {
		${media.maxSm`
		display: none;
	`}
	}
	${P} {
		max-width: 1059px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 0;
	}
`;

const DescriptionBlock = () => (
	<StyledDescriptionBlock>
		<PageDescription
			title="We Develop iPhone Apps That Amaze!"
			subtitle="Advanced solutions for business transformation"
			bgColor="white"
			paddingBottom={'0'}
		>
			<P className="text-left">
				At <strong>PixelTeh</strong>, we know the perfect way to accelerate your
				business. Even though a big budget and creative ideas can get you far,
				you won’t really stand out in the digital world without a customized
				iPhone app. Let us accelerate, transform, and innovate your business.
				Since more than 60% of internet traffic comes from mobile devices, your
				business needs a custom iOS app that engages your target audience and
				drives profits.
			</P>
		</PageDescription>
	</StyledDescriptionBlock>
);

export default DescriptionBlock;
