import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import CenterAnimatedButton from '../../_common/CenterAnimatedButton';
import H3 from '../../_common/H3';
import H4 from '../../_common/H4';
import P from '../../_common/P';
import { media, colors, fonts } from '../../../globalStyles';
import mlIcon from '../../../assets/img/ml.png';
import arIcon from '../../../assets/img/ar.png';
import leftArrow from '../../../assets/img/left-arrow.png';
import verticalStripeImg from '../../../assets/img/vertical-stripes.png';
import accordionLine from '../../../assets/img/accordion-line.png';

const StyledApplying = styled.section`
	background-color: ${colors.whiteSmoke};
    padding-top: 83px;
    margin-left: -15px;
	margin-right: -15px;
    padding-bottom: 100px;
    margin-bottom: 100px;
    ${media.maxSm`
        padding-top: 77px;
	    padding-bottom: 50px;
	    margin-bottom: 30px;
    `}
    .container-fluid {
    	display: flex;
    	justify-content: space-between;
    	max-width: 1585px;
    	padding-left: 0;
    	padding-right: 0;
    	background: url(${verticalStripeImg}) repeat-y 50% 0;
    	${media.tablet`
	        flex-direction: column;
	        background: none;
    	`}
    }
    .applying-item {
    	display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
	    width: 50%;
	    ${media.tablet`width: 100%`}
	    ${media.maxSm`margin-bottom: 20px;`}
	    padding-right: 15px;
	    padding-left: 15px;
	    figure {min-height: 155px;}
	    ${H3} {
	    	color: ${colors.blackTitle};
		    font-size: 26px;
		    font-family: ${fonts.robotoRegular};
		    line-height: 32px;
		    text-align: center;
		    padding: 20px 0;
		    ${media.maxSm`padding: 40px 0;`}
	    }
	    ${H4} {
	    	max-width: 80%;
	    	margin-top: 10px;
	    	margin-bottom: 10px;
	    	color: ${colors.blackTitle};
	    	font-family: ${fonts.robotoBold};
		    font-size: 48px;
		    line-height: 54px;
		    text-align: center;
		    ${media.laptop`font-size: 38px;`}
		    ${media.maxMd`font-size: 30px;`}
		    ${media.maxSm`
		        font-size: 34px;
			    line-height: 44px;
			    margin-top: 18px;
			    max-width: 100%;
		    `}
	    }
	    ${P} {
	    	color: ${colors.greyItalicTitle};
		    font-size: 17px;
		    font-family: ${fonts.robotoLightItalic};
		    line-height: 32px;
		    padding: 72px 10px;
		    text-align: center;
		    ${media.laptopS`padding: 50px 10px;`}
		    ${media.maxSm`padding: 44px 0;`}
		    a{
				text-decoration: none;
				color: ${colors.greyItalicTitle};
				font-family: ${fonts.openSansBold};
			}
	    }
	    .applying-item-link {
	    	a {
	    		display: flex;
	    		align-items: center;
	    		color: ${colors.orange};
			    font-family: ${fonts.openSans};
			    font-size: 17px;
			    font-weight: 600;
			    text-transform: uppercase;
			    text-decoration: none;
			    img {
			    	margin-left: 10px;
			    }
	    	}
	    }
    }
    .applying-border {
    	display: none;
    	height: 14px;
	    background: url(${accordionLine});
	    width: 80%;
	    margin: 0 auto;
	    margin-top: 50px;
	    margin-bottom: 50px;
	    ${media.tablet`display: block`}
    }
`;

const Applying = () => (
	<ContentSection
		title="Applying ML, VR & AR in iOS Apps"
		subtitle="Creating Smarter & Scalable Apps Using Emerging Technologies"
		fluid
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<StyledApplying>
			<div className="container-fluid">
				<div className="applying-item">
					<figure>
						<img src={mlIcon} alt="ML machine learning button" />
					</figure>
					<H3>Machine Learning</H3>
					<H4>Build Intelligent APPs</H4>
					<P>
						By using programs like Core ML, we make your customized app smarter
						by adding just a few lines of code.
						<Link to={'/technologies-machine-learning'}>
							{' '}
							Machine learning{' '}
						</Link>
						enables a more meaningful experience. Examples of ML include face
						and pattern recognition, text identification, barcode scanning,
						image labeling, and landmark recognition.
					</P>
					<div className="applying-item-link">
						<Link to="/technologies-machine-learning">
							Learn more about Machine Learning
							<img src={leftArrow} alt="orange arrow pointing right" />
						</Link>
					</div>
				</div>
				<div className="applying-border" />
				<div className="applying-item">
					<figure>
						<img src={arIcon} alt="augmented reality button" />
					</figure>
					<H3>Augmented & Virtual Reality</H3>
					<H4>This is reality – only better!</H4>
					<P>
						You want an app that is much better than all the rest. Our coders
						use ARKit and other AR programs, which provide positional tracking
						and scene understanding to create immersive augmented reality apps.
						The result is something that goes well beyond the ordinary. It’s an
						app that comes alive!
					</P>
					<div className="applying-item-link">
						<Link to="/technologies-ios">
							Learn More about AR & VR
							<img src={leftArrow} alt="orange arrow pointing right" />
						</Link>
					</div>
				</div>
			</div>
		</StyledApplying>
		<CenterAnimatedButton
			linkTo="/contact#content-section"
			gaLabel={'technologies-ios Start a project with us'}
		>
			Start a project with us
		</CenterAnimatedButton>
	</ContentSection>
);

export default Applying;
