import React from 'react';
import styled from 'styled-components';
import IosAccordeon from '../IosAccordeon';
import H3 from '../../_common/H3';
import H4 from '../../_common/H4';
import P from '../../_common/P';
import { media, fonts, colors } from '../../../globalStyles';
import verticalStripes from '../../../assets/img/vertical-stripes.png';

const StyledFeatures = styled.section`
	background-color: ${colors.blueDark};
    font-family: ${fonts.robotoRegular};
    text-align: center;
    padding-bottom: 58px;
    color: ${colors.white};
    ${media.maxSm`
        background: #fff;
        padding-bottom: 30px;
    `}
    .container-fluid {
    	max-width: 1600px;
    }
	${H3} {
		font-size: 26px;
	    font-weight: 400;
	    text-transform: uppercase;
	    padding-top: 73px;
	    ${media.maxSm`
		    color: ${colors.blackTitle};
		    font-family: ${fonts.robotoBold};
		    font-size: 20px;
		    font-weight: 500;
		    line-height: 32px;
		    text-transform: inherit;
		    padding-bottom: 30px;
		    padding-top: 30px;
	    `}
	    & + ${P} {
		        color: ${colors.white};
			    padding-top: 50px;
			    padding-bottom: 50px;
		        font-family: ${fonts.openSans};
			    font-size: 15px;
			    line-height: 30px;
			    ${media.maxSm`
				    padding-top: 0;
				    text-align: justify;
				    color: ${colors.blackTitle};
			    `}
	    }
	}
	.features-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-left: 0;
		.feature {
			padding-left: 15px;
			padding-right: 15px;
			${media.minSm`
				width: 50%;
			`}
			${media.minLg`
				width: 25%;
			`}
			&:not(:last-of-type) {
				${media.minLg`
					background: url(${verticalStripes}) repeat-y 100% 0;
				`}
			}
			${H4} {
				font-size: 17px;
			    font-weight: 500;
			    line-height: 24px;
			    text-transform: uppercase;
			    margin: 3px 0 0 0;
			    ${media.maxMd`
			        font-size: 15px;
			    `}
			}
			hr {
				width: 151px;
			    height: 1px;
			    background: ${colors.orange};
			    color: inherit;
			    border: none;
			    margin-top: 18px;
			    margin-bottom: 33px;
			}
			${P} {
				color: ${colors.white};
				font-family: ${fonts.openSans};
			    font-style: italic;
			    line-height: 28px;
			    padding: 0 50px 10px 50px;
			    ${media.maxLg`
			        padding: 0 10px 20px 10px;
			    `}
			}
 		}
	}
`;

const Features = () => (
	<StyledFeatures>
		<div className="container-fluid">
			<H3 as={'h2'}>Compelling Reasons to Build an iPhone App</H3>
			<P>
				It’s in their hands, pockets, or purses. Wherever they go, people carry
				their iPhones. Are you going to let this opportunity slip from your
				hands? An iOS app is the perfect way to advertise your products and
				services, promote your business, and generate interest in your brand.
				More than a TV commercial or billboard, an app for iPhones creates an
				immersive experience. Whether it’s a game, an augmented reality app, an
				eCommerce app, or an application that provides a service for your
				end-users, you can’t afford to miss out on letting <b>PixelTeh</b>{' '}
				develop an iOS app that makes your customers say, “Wow!”
			</P>
			<ul className="features-list hidden-sm">
				<li className="feature">
					<H4>Build a Stronger Brand</H4>
					<hr />
					<P>
						In today’s mobile-driven world, one of the best ways to develop
						brand recognition is with an iPhone app. Let us develop an iPhone
						app to educate, engage, and excite your customers.
					</P>
				</li>
				<li className="feature">
					<H4>Keep the Connections Open</H4>
					<hr />
					<P>
						More than 4.5 billion people use mobile devices on a daily basis.
						Stay connected by taking advantage of a game-changer that allows for
						real-time, 24-hour interactions.
					</P>
				</li>
				<li className="feature">
					<H4>Greater User Engagement</H4>
					<hr />
					<P>
						A well-built and targeted mobile app increases the level of
						engagement with your business. And more engagement means more
						interest and more purchases.
					</P>
				</li>
				<li className="feature">
					<H4>Faster Mobile Transactions</H4>
					<hr />
					<P>
						Online shopping is where it’s at. Put your products and services
						right in front of them and ready to buy. More than 80% of people
						shop via their mobile phones.
					</P>
				</li>
			</ul>
		</div>
		<IosAccordeon />
	</StyledFeatures>
);

export default Features;
